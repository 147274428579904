<template>
  <vx-card>
    <h1>إضافة مشرف جديد</h1>
    <div class="mb-5">&#8203;</div>
    <!-- ////////////////////// -->
    <div class="vx-row">
      <div class="vx-col my-1 w-full mb-6">
        <div class="con-img-upload flex" id="images" v-if="newAdmin.media.length">
          <div class="img-upload" v-for="(image, index) in newAdmin.media" :key="index">
            <button type="button" class="btn-x-file" @click="spliceFile(index)">
              <i translate="translate" class="material-icons notranslate">clear</i>
            </button>
            <img v-if="image.blob" :src="image.blob" style="max-width: none; max-height: 100%" />
          </div>
        </div>
        <file-upload ref="upload" :multiple="true" v-model="newAdmin.media" class="vs-button bg-primary mt-5"
          post-action="/post.method" put-action="/put.method" @input-filter="inputFilter"> تنزيل صورة
        </file-upload>
        <span class="text-danger text-sm" v-show="media_error">{{
             media_error
            }}</span>
      </div>
    </div>
    <!-- //////////////////////// -->
    <div class="vx-row">
      <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
        <vs-input data-vv-as="إسم المستخدم" v-validate="'required'" class="w-full" label-placeholder="إسم المستخدم"
          v-model="newAdmin.username" name="name" />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first("name")
        }}</span>
      </div>
    </div>

    <div class="vx-row mt-8">
      <div class="vx-col my-3 sm:w-1/4 w-full mb-2">
        <vs-input data-vv-as="رقم الهاتف" v-validate="'required'" class="w-full" label-placeholder="رقم الهاتف"
          v-model="newAdmin.phone" name="phone" />
        <span class="text-danger text-sm" v-show="errors.has('phone')">{{
          errors.first("phone")
        }}</span>
      </div>
      <div class="vx-col my-3 sm:w-1/3 w-full flex items-center mt-8">
          <label class="vs-input--label mr-3">الدور : </label>
          <v-select
            multiple 
            v-model="newAdmin.role"
            name="roleErr"
            v-validate="'required'"
            class="w-1/2"
            data-vv-as="الدور"
            label="display_name"
            :reduce="(item) => item.id"
            :options="roles"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('order-items.roleErr')"
            >{{ errors.first("order-items.roleErr") }}</span
          >
      </div>
    </div>
    <div class="vx-row mt-8">
      <div class="vx-col my-3 sm:w-1/4 w-full mb-2">
        <vs-input type="password" data-vv-as="كلمة المرور" v-validate="'required'" class="w-full"
          label-placeholder="كلمة المرور" v-model="newAdmin.password" name="password" />
        <span class="text-danger text-sm" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>
      </div>
      <div class="vx-col my-3 sm:w-1/4 w-full mb-2">
        <vs-input type="password" data-vv-as="تأكيد كلمة المرور" v-validate="'required'" class="w-full"
          label-placeholder="تأكيد كلمة المرور" v-model="newAdmin.password_confirmation" name="password_confirmation" />
        <span class="text-danger text-sm" v-show="errors.has('password_confirmation')">{{
          errors.first("password_confirmation")
        }}</span>
      </div>

    </div>
    <div class="vx-row mt-8 w-full ">
      <div class="vx-col flex justify-end w-full mb-6">
        <vs-button class="mr-5 text-lg py-4" color="warning" type="border">إلغاء</vs-button>
        <vs-button class="mr-5 text-lg px-16 py-4" @click="submit" color="success" type="filled">حفظ</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import vSelect from "vue-select";
  import VueUploadComponent from 'vue-upload-component'
  import moduleUser from '@/store/users/moduleUser.js'

  export default {
    components: {
      vSelect,
      'file-upload': VueUploadComponent
    },
    data() {
      return {
        media_error:'',
        roles:[],
        newAdmin: {
          username: "",
          phone: null,
          password: "",
          media: [],
          password_confirmation: "",
          role: []
        }
      };
    },

    methods: {
     
      async submit() {
        let result = await this.$validator.validateAll()
        let roles = this.newAdmin.role
        if (result) {
          // this.$vs.loading();
          var bodyFormData = new FormData();
          bodyFormData.set("username", this.newAdmin.username);
          bodyFormData.set("phone", this.newAdmin.phone);
          bodyFormData.set("password", this.newAdmin.password);
          bodyFormData.set("password_confirmation", this.newAdmin.password_confirmation);
          roles.forEach((item) => bodyFormData.append("roles[]", item))

          bodyFormData.append("image", this.newAdmin.media[0].file || null);

          this.$store
            .dispatch("user/createAdmin", bodyFormData)
            .then(() => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم اضافة المشرف بنجاح",
                color: "success",
              });
                this.$router.push('/admins')
            })
            .catch((error) => {
              this.$vs.loading.close();

              this.$vs.notify({
                time: 5000,
                title: "حدث خطأ",
                text: "يرجى اعادة المحاولة",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

              if(error.response.data.errors.media){
                this.media_error = 'حقل الصورة مطلوب يرجى اضافة على الاقل صورة واحدة'
              }
            });
        }
      },
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
            return prevent();
          }
        }
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      },
      spliceFile(index) {
        this.contest.media.splice(index, 1);
      },

    },
    mounted(){
       this.$store.dispatch("user/getRoles").then((response) => {
        this.roles = response.data.roles;
      });
    },
    created() {
    if(!moduleUser.isRegistered){
      this.$store.registerModule("user",moduleUser);
      moduleUser.isRegistered = true;
    }
  },
  };

</script>

<style lang="scss">
  .btn-upload-file {
    display: none;
  }

</style>
